import React from "react"
import Layout from "../component/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Lightbox from "../component/Lightbox"
// import Availability from "../component/AvailabilityDh"
import ButtonsF from "../component/ButtonsF"
// eslint-disable-next-line
import { Link, animateScroll as scroll } from "react-scroll"

// import towels from "../images/icons/001-towel.png"
// import heating from "../images/icons/003-hot-thermometer.png"
import nature from "../images/icons/wood copy.png"
// import hair from "../images/icons/004-hair-dryer.png"
import pets from "../images/icons/012-animal-prints.png"
import parking from "../images/icons/010-parking.png"
// import wood from "../images/icons/001-log.png"
// import cutlery from "../images/icons/014-cutlery.png"
// import shower from "../images/icons/shower.png"
import fridge from "../images/icons/001-fridge.png"
import cook from "../images/icons/003-oven-mitt.png"
// import oven from "../images/icons/002-stone-oven.png"
import toilet from "../images/icons/toilet.png"
import party from "../images/icons/002-fireworks.png"
import bar from "../images/icons/martini.png"

import family from "../images/icons/013-family.png"
// import dish from "../images/icons/dishwasher.png"
// import iron from "../images/icons/005-iron.png"
// import tele from "../images/icons/008-television.png"
// import hanger from "../images/icons/006-hanger.png"
// import jacu from "../images/icons/007-jacuzzi.png"

import SEO from "../component/SEO"

const barn = ({ data }) => {
  return (
    <Layout>
      <SEO title="Dorlands Barn" />
      <section className="hero-content barn">
        <div className="hero-title" id="barn-hero-title">
          <div className="inner-wrap">
            <h1>The Barn @ Dorlands</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
        <ButtonsF scroll="dhouse" />
      </section>
      <section className="intro-content welcome-dch">
        <div className="slogan">
          <h2>The Barn @ Dorlands</h2>
          <p>The Barn is a great place for big parties, Weddings and events.</p>
        </div>
      </section>
      <section className="three-pictures-section dorlands_barn_three_home">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          s
          className="picture pic-three"
        ></Link>
      </section>
      <section className="intro-content gardens-dch">
        <div className="slogan">
          <h2>GARDEN &amp; GROUNDS</h2>

          <p>
            The expansive garden allows lots of room for throwing the frisbee or
            kicking a ball about. If that sounds a bit too energetic, then
            simply sit on the patio with a nice glass of something, and enjoy
            the breathtaking views, whilst listening to the family of buzzards
            that circle the valley and the nearby woodland.
          </p>
          {/* <p>
            The patio also houses the hot tub, and there is plenty of seating
            for everyone, around the teak tables The BBQ’s are alongside.
          </p> */}
        </div>
      </section>
      {/* <section className="picture-break house-break"></section> */}
      {/* <section className="intro-content cooking-dch">
        <div className="slogan">
          <h2>COOKING AND DINING</h2>
          <p>
            People gravitate to the kitchen, it has lots of room for people to
            chat round the table (maybe helping to prepare the veg.), and catch
            up on each others news.
          </p>
          <p>
            The honey coloured flagstone floors, and the granite work tops are
            very practical yet stylish. The large range cooker with double ovens
            allows for lots of great food preparation.
          </p>
          <p>
            Once all the goodies are ready, call everyone together to the dining
            room, to enjoy.
          </p>
        </div>
      </section> */}
      <section className="two-pictures-section dorlands_barn_two_home">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
      </section>
      <section className="intro-content living-dch">
        <div className="slogan">
          <h2>EVENT SPACE</h2>
          <p>
            The barn can be arranged in a any way that suits your event. We will
            do our best to accomadate you, no matter how extravagant your ideas
            may be.
          </p>
        </div>
      </section>
      <section className="three-pictures-section dorlands_barn_three_home_2">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-three"
        ></Link>
      </section>
      {/* <section className="intro-content sleeps-dch">
        <div className="slogan">
          <h2>SLEEPING</h2>
          <p>
            7 large bedrooms, offer flexible sleeping arrangements and layouts.
            Everything is prepared for your arrival, crisp cotton sheets,
            delicious duvets and fluffy towels adorn the beds.
          </p>
        </div>
      </section> */}

      <section className="info-content">
        <div className="amenities-content">
          <h4>Amenities</h4>
          <div className="grid">
            {/* <div className="icons">
              <img src={towels} alt="towel and bedding icon" />
              <p className="iconstext">Towels &amp; Bedding</p>
            </div> */}
            {/* <div className="icons">
              <img src={heating} alt="icon" />
              <p className="iconstext">Electric Heater</p>
            </div> */}
            <div className="icons">
              <img src={party} alt="party icon" />
              <p className="iconstext">Perfect for Parties</p>
            </div>
            {/* <div className="icons">
              <img src={machine} alt="washing machine icon" />
              <p className="iconstext">Washing Machine</p>
            </div>
            <div className="icons">
              <img src={bath} alt="bath icon" />
              <p className="iconstext">Baths</p>
            </div> */}
            <div className="icons">
              <img src={family} alt="familt friendly icon" />
              <p className="iconstext">Family Friendly</p>
            </div>
            {/* <div className="icons">
              <img src={wifi} alt="wifi icon" />
              <p className="iconstext">Wifi</p>
            </div> */}
            {/* <div className="icons">
              <img src={dish} alt="diswasher icon" />
              <p className="iconstext">Dishwasher</p>
            </div> */}
            {/* <div className="icons">
              <img src={iron} alt="iron icon" />
              <p className="iconstext">Iron</p>
            </div> */}
            {/* <div className="icons">
              <img src={tele} alt="television icon" />
              <p className="iconstext">Television</p>
            </div> */}
            {/* <div className="icons">
              <img src={hanger} alt="hanger icon" />
              <p className="iconstext">Hangers</p>
            </div> */}
            <div className="icons">
              <img src={nature} alt="icon" />
              <p className="iconstext">Enjoy Nature</p>
            </div>
            {/* <div className="icons">
              <img src={hair} alt="icon" />
              <p className="iconstext">Hair Dryers</p>
            </div> */}
            <div className="icons">
              <img src={pets} alt="icon" />
              <p className="iconstext">Pet Friendly</p>
            </div>
            <div className="icons">
              <img src={parking} alt="icon" />
              <p className="iconstext">On site Parking</p>
            </div>
            {/* <div className="icons">
              <img src={wood} alt="icon" />
              <p className="iconstext">Wood Burner</p>
            </div> */}
            {/* <div className="icons">
              <img src={jacu} alt="icon hot tub" />
              <p className="iconstext">Hot Tub</p>
            </div>
            <div className="icons">
              <img src={cutlery} alt="icon" />
              <p className="iconstext">Cutlery</p>
            </div> */}
            {/* <div className="icons">
              <img src={shower} alt="icon" />
              <p className="iconstext">Shower</p>
            </div> */}
            <div className="icons">
              <img src={bar} alt="icon" />
              <p className="iconstext">Bar</p>
            </div>
            <div className="icons">
              <img src={fridge} alt="icon" />
              <p className="iconstext">Fridge</p>
            </div>
            <div className="icons">
              <img src={cook} alt="icon" />
              <p className="iconstext">Cooking Facilities</p>
            </div>
            {/* <div className="icons">
              <img src={oven} alt="icon" />
              <p className="iconstext">Pizza Oven</p>
            </div> */}
            <div className="icons">
              <img src={toilet} alt="icon" />
              <p className="iconstext">WC's</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="intro-content hot-tub-dch">
        <div className="slogan">
          <h2>HOT TUB</h2>
          <p>
            There is nothing more delightful than relaxing in the warm bubbling
            waters, whilst enjoying a panoramic view !
          </p>
          <p>
            We do not like hidden extra costs for anything to do with booking
            our properties, we want to declare everything upfront and we like to
            provide all the essentials for you in the stated costs. However, not
            all our guests choose to use the hot tub. We have thought long and
            hard about this and have decided that each group should make the
            decision whether they will use it or not.
          </p>
          <p>
            The preparation of the tub,involves fresh filters, fresh water which
            is heated for 24 hours before each visit. It is a commitment to a
            lot of energy and resources, and only makes sense to provide it if
            it is wanted. If you choose to book the hot tub it will cost £120,
            it can be paid for separately and closer to the time of your visit.
          </p>
        </div>
      </section> */}
      {/* <section className="picture-break country-house-break-3"></section> */}

      <section className="galleries" id="dhouse">
        <h2>The Barn @ Dorlands Gallery</h2>
        <Lightbox images={data.barn.edges} />
      </section>
      {/* <section id="avail">
        <Availability />
      </section> */}
    </Layout>
  )
}

barn.propTypes = {
  data: PropTypes.object.isRequired,
}

export default barn

export const galleryQueryOne = graphql`
  query BarnGallery {
    barn: allFile(filter: { sourceInstanceName: { eq: "barn" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
